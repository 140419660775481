<template>
    <div
        class="custom-parameters-form grid grid-cols-1 gap-y-4 gap-x-4 md:grid-cols-3 mt-4"
    >
        <div>
            <div class="mt-1 relative rounded-md">
                <input
                    :id="'input-key_' + item.id"
                    v-model="key"
                    v-on:change="updateItem()"
                    class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    placeholder="Parameter name"
                    type="text"
                    data-test="input-key"
                />
            </div>
        </div>
        <div>
            <div class="mt-1 relative rounded-md">
                <input
                    :id="'input-value_' + item.id"
                    v-model="value"
                    v-on:change="updateItem()"
                    class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    placeholder="Value"
                    type="text"
                />
            </div>
        </div>
        <div class="flex items-center">
            <a
                href="#"
                @click.prevent="deleteItem(item.id)"
                class="font-medium text-sm text-gray-600 hover:text-indigo-500"
            >
                Remove
            </a>
        </div>
    </div>
</template>

<style lang="scss">
.custom-parameters-form {
    @media (min-width: 768px) {
        grid-template-columns: auto auto min-content !important;
    }
}
</style>

<script lang="ts">
export default {
    name: "KeyValueInput",
    props: {
        item: Object,
    },
    data: function () {
        return {
            key: "",
            value: "",
            isLoading: false,
        };
    },
    created() {
        this.key = this.item.key;
        this.value = this.item.value;
    },
    methods: {
        updateItem() {
            this.$emitter.emit("updateCustomParameter", {
                id: this.item.id,
                key: this.key,
                value: this.value,
            });
        },
        deleteItem(id) {
            this.$emitter.emit("removeCustomParameter", id);
        },
    },
};
</script>
