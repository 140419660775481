<template>
    <ul
        class="mb-3 bg-white border border-gray-300 overflow-hidden sm:rounded-md"
        data-test="url-list"
    >
        <li v-if="isLoading">
            <div
                class="px-4 py-6 sm:px-8 text-center flex justify-center items-center"
            >
                <div>
                    <svg
                        class="animate-spin -ml-1 mr-3 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                        ></circle>
                        <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                </div>
                <div>Loading links..</div>
            </div>
        </li>
        <li v-if="items.length === 0 && !isLoading">
            <div class="px-4 py-6 sm:px-8 text-center">No urls found</div>
        </li>
        <template v-for="item in items" :key="item.linkId">
            <li v-if="items.length !== 0 && !isLoading">
                <UrlListItem :item="item" :items="items" />
            </li>
        </template>
    </ul>
</template>

<script lang="ts">
import UrlListItem from "../components/UrlListItem.vue";

export default {
    name: "UrlList",
    components: {
        UrlListItem,
    },
    props: {
        items: Array,
        isLoading: Boolean,
    },
};
</script>
