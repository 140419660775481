import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "navigation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/",
      class: "navigation-link border-r"
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("span", { class: "text-xs" }, "Home", -1)
      ])),
      _: 1
    }),
    _createVNode(_component_router_link, {
      to: "/config",
      class: "navigation-link border-r"
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createElementVNode("span", { class: "text-xs" }, "Settings", -1)
      ])),
      _: 1
    })
  ]))
}