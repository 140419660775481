import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "config-page" }
const _hoisted_2 = { class: "mt-10" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "space-y-8 divide-y divide-gray-200"
}
const _hoisted_5 = { class: "space-y-8 divide-y divide-gray-200 sm:space-y-5" }
const _hoisted_6 = { class: "mt-6 sm:mt-5 space-y-6 sm:space-y-5" }
const _hoisted_7 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_8 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_9 = {
  key: 0,
  class: "text-red-500 text-xs mt-1"
}
const _hoisted_10 = { class: "flex justify-end" }
const _hoisted_11 = { class: "text-center text-sm text-gray-400 mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopMenu = _resolveComponent("TopMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TopMenu),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[2] || (_cache[2] = [
            _createElementVNode("div", { class: "px-4 py-6 sm:px-8 text-center flex justify-center items-center" }, [
              _createElementVNode("div", null, [
                _createElementVNode("svg", {
                  class: "animate-spin -ml-1 mr-3 h-5 w-5",
                  xmlns: "http://www.w3.org/2000/svg",
                  fill: "none",
                  viewBox: "0 0 24 24"
                }, [
                  _createElementVNode("circle", {
                    class: "opacity-25",
                    cx: "12",
                    cy: "12",
                    r: "10",
                    stroke: "currentColor",
                    "stroke-width": "4"
                  }),
                  _createElementVNode("path", {
                    class: "opacity-75",
                    fill: "currentColor",
                    d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  })
                ])
              ]),
              _createElementVNode("div", null, "Loading ..")
            ], -1)
          ])))
        : (_openBlock(), _createElementBlock("form", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", null, [
                _cache[5] || (_cache[5] = _createElementVNode("div", null, [
                  _createElementVNode("h3", { class: "text-lg leading-6 font-medium text-gray-900" }, " App settings ")
                ], -1)),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _cache[4] || (_cache[4] = _createElementVNode("label", {
                      for: "about",
                      class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    }, " Supported external domains ", -1)),
                    _createElementVNode("div", _hoisted_8, [
                      _withDirectives(_createElementVNode("textarea", {
                        id: "about",
                        name: "about",
                        rows: "5",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.allowedDomains) = $event)),
                        placeholder: "https://example.com",
                        class: "max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      }, null, 512), [
                        [_vModelText, _ctx.allowedDomains]
                      ]),
                      (this.errors.textAreaUrls)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.errors.textAreaUrls), 1))
                        : _createCommentVNode("", true),
                      _cache[3] || (_cache[3] = _createElementVNode("p", { class: "mt-2 text-sm text-gray-500" }, " Separate domain(s) one per line. ", -1))
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("button", {
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($options.post()), ["prevent"])),
                  class: "flex items-center mt-4 px-4 py-3 text-sm leading-6 font-light rounded-md text-white focus:outline-none bg-emerald-100 bg-emerald-500 md:py-2 md:px-6"
                }, " Save ")
              ])
            ])
          ]))
    ]),
    _createElementVNode("div", _hoisted_11, _toDisplayString($options.info), 1)
  ]))
}