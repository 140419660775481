import store from "../store";
import * as _ from "lodash";

const validateLink = (url: string) => {
    const reUrl =
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;

    if (!url.length) {
        return {
            valid: false,
            error: "This field is required",
            rule: "required",
        };
    } else if (!url.startsWith("https://")) {
        return {
            valid: false,
            error: "Should start with https://",
            rule: "only-https",
        };
    } else if (!url.match(reUrl)) {
        return { valid: false, error: "Invalid url", rule: "valid-url" };
    }
    return { valid: true, error: null, rule: null };
};

const validateLinkAndDomain = (url: string) => {
    // First validate link rules
    const link = validateLink(url);

    if (!link.valid) {
        return link;
    }

    // Validate external domains
    const externalDomains = store.getters.getExternalDomains;
    const inputUrl = new URL(url);
    let matchFound = false;

    // iterate external domains
    for (const url of externalDomains) {
        const allowedUrl = new URL(url);

        if (allowedUrl.hostname === inputUrl.hostname) {
            matchFound = true;
        }
    }

    if (!matchFound) {
        return {
            valid: false,
            error: `${inputUrl.hostname} external domain is not allowed`,
            rule: "only-allowed-domain",
        };
    }

    return { valid: true, error: null, rule: null };
};

const validateShortName = (name: string, items) => {
    const isNameTaken = _.find(items, { shortName: name });
    const reLettersNumbers = /^[a-z0-9_-]+$/i;

    if (!name.length) {
        return {
            valid: false,
            error: "This field is required",
            rule: "required",
        };
    } else if (isNameTaken) {
        return {
            valid: false,
            error: "This slug is already taken",
            rule: "unique",
        };
    } else if (!name.match(reLettersNumbers)) {
        return {
            valid: false,
            error: 'Letters, numbers, "-" and "_" allowed',
            rule: "only-allowed-symbols",
        };
    }
    return { valid: true, error: null, rule: null };
};

const validateShortNameLength = (urlString) => {
    if (urlString.length > 37) {
        return {
            valid: false,
            warning:
                "Part of the link will be hidden in YT (link length > 37 symbols)",
            rule: "length-warning",
        };
    } else {
        return { valid: true, warning: null, rule: null };
    }
};

const validateTagName = (name) => {
    const reLettersNumbers = /^[a-z0-9_-]+$/i;

    if (name && name.length && !name.match(reLettersNumbers)) {
        return {
            valid: false,
            error: 'Letters, numbers, "-" and "_" allowed',
            rule: "only-allowed-symbols",
        };
    }
    return { valid: true, error: null, rule: null };
};

const validateShortAndTagNames = (shortName, tagName, items) => {
    if (shortName && tagName) {
        const areNamesTaken = _.find(items, {
            shortName: shortName,
            tagName: tagName,
        });

        if (areNamesTaken) {
            return {
                valid: false,
                error: "Tag for this name is already taken",
                rule: "unique",
            };
        }
    }

    return { valid: true, error: null, rule: null };
};

const validateRequired = (value) => {
    if (!value || !value.length) {
        return { valid: false, error: "This field is required", rule: "" };
    }
    return { valid: true, error: null, rule: null };
};

const validateTextAreaUrls = (text) => {
    if (!text.length) {
        return {
            valid: false,
            error: "This field is required",
            rule: "required",
        };
    } else {
        const lines = text.split("\n");

        for (const line of lines) {
            const link = validateLink(line);
            if (!link.valid) {
                return link;
            }
        }
    }

    return { valid: true, error: null, rule: null };
};

export {
    validateLink,
    validateLinkAndDomain,
    validateShortName,
    validateShortNameLength,
    validateTagName,
    validateShortAndTagNames,
    validateRequired,
    validateTextAreaUrls,
};
