import { createApp } from "vue";
import mitt from "mitt";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { toast } from "vue3-toastify";
import Vue3Toastify, { type ToastContainerOptions } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "./assets/css/tailwind.css";

const app = createApp(App);

app.use(router);
app.use(store);

app.use(Vue3Toastify, {
    autoClose: 2500,
} as ToastContainerOptions);

app.config.globalProperties.$toast = toast;
app.config.globalProperties.$emitter = mitt();
app.config.globalProperties.$filters = {
    formatTimestamp(timestamp) {
        const datetime = new Date(timestamp);
        return (
            datetime.toLocaleDateString("lt-LT") +
            " " +
            datetime.toLocaleTimeString("lt-LT")
        );
    },
};

app.mount("#app");
