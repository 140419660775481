<template>
    <div id="app" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <router-view />
    </div>
</template>

<style lang="scss">
#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

#plus {
    padding: 0 20px 0 20px;
    display: inline-block;
    font-size: 50px;
    vertical-align: top;
    line-height: 100px;
}
</style>
