<template>
    <div class="config-page">
        <TopMenu></TopMenu>

        <div class="mt-10">
            <div v-if="isLoading">
                <div
                    class="px-4 py-6 sm:px-8 text-center flex justify-center items-center"
                >
                    <div>
                        <svg
                            class="animate-spin -ml-1 mr-3 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                class="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="4"
                            ></circle>
                            <path
                                class="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                    </div>
                    <div>Loading ..</div>
                </div>
            </div>
            <form v-else class="space-y-8 divide-y divide-gray-200">
                <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div>
                        <div>
                            <h3
                                class="text-lg leading-6 font-medium text-gray-900"
                            >
                                App settings
                            </h3>
                        </div>
                        <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            <div
                                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
                            >
                                <label
                                    for="about"
                                    class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    Supported external domains
                                </label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <textarea
                                        id="about"
                                        name="about"
                                        rows="5"
                                        v-model="allowedDomains"
                                        placeholder="https://example.com"
                                        class="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                                    ></textarea>
                                    <div
                                        v-if="this.errors.textAreaUrls"
                                        class="text-red-500 text-xs mt-1"
                                    >
                                        {{ errors.textAreaUrls }}
                                    </div>
                                    <p class="mt-2 text-sm text-gray-500">
                                        Separate domain(s) one per line.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="flex justify-end">
                        <button
                            @click.prevent="post()"
                            class="flex items-center mt-4 px-4 py-3 text-sm leading-6 font-light rounded-md text-white focus:outline-none bg-emerald-100 bg-emerald-500 md:py-2 md:px-6"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div class="text-center text-sm text-gray-400 mt-10">{{ info }}</div>
    </div>
</template>

<style lang="scss">
.config-page {
    label {
        font-weight: 600;
    }

    textarea {
        padding-top: 0.5rem;
        padding-right: 0.75rem;
        padding-bottom: 0.5rem;
        padding-left: 0.75rem;
    }
}
</style>

<script lang="ts">
// @ is an alias to /src
import TopMenu from "../components/TopMenu.vue";
import $backend from "../backend";
import store from "../store";
import { validateTextAreaUrls } from "../common/validators";

export default {
    name: "config-page",
    components: {
        TopMenu,
    },
    data: function () {
        return {
            allowedDomains: "",
            isLoading: false,
            errors: {},
            logoUrl: store.getters.getLogo,
        };
    },
    methods: {
        post() {
            const validTextAreaUrls = validateTextAreaUrls(this.allowedDomains);
            this.errors.textAreaUrls = validTextAreaUrls.error;

            if (validTextAreaUrls.valid) {
                // reset errors
                this.errors = {};

                const allowedDomainsList = this.allowedDomains.split("\n");
                const data = {
                    allowedDomain: allowedDomainsList,
                };

                $backend
                    .postConfig(data)
                    .then((response) => {
                        if (response.error) {
                            this.$toast.error(`Save failed: ${response.error}`);
                        }

                        this.$toast.success("Configuration saved!");
                        store.dispatch(
                            "setExternalDomains",
                            allowedDomainsList
                        );
                    })
                    .catch((error) => {
                        this.$toast.error(`Save failed: ${error.message}`);
                    })
                    .finally(() => {
                        this.savingLink = false;
                    });
            }

            // Trigger UI update
            this.$forceUpdate();
        },
        fetchConfig() {
            const externalDomains = store.getters.getExternalDomains;

            if (externalDomains.length === 0) {
                this.isLoading = true;

                $backend
                    .fetchConfig()
                    .then((data) => {
                        if (data.error) {
                            console.error("error: " + data.error);
                        } else if (data.allowedDomain.length) {
                            store.dispatch(
                                "setExternalDomains",
                                data.allowedDomain
                            );
                            this.allowedDomains = data.allowedDomain.join("\n");
                        }
                    })
                    .catch((error) => {
                        console.error("error: " + error.message);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            } else {
                this.allowedDomains = externalDomains.join("\n");
            }
        },
    },
    created() {
        this.fetchConfig();
    },
    computed: {
        info() {
            return `${process.env.NODE_ENV} - ${process.env.VUE_APP_VERSION} - ${process.env.VUE_APP_DATE_DEPLOY}`;
        },
    },
};
</script>
