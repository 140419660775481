import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "mb-3 bg-white border border-gray-300 overflow-hidden sm:rounded-md",
  "data-test": "url-list"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UrlListItem = _resolveComponent("UrlListItem")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    ($props.isLoading)
      ? (_openBlock(), _createElementBlock("li", _hoisted_2, _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "px-4 py-6 sm:px-8 text-center flex justify-center items-center" }, [
            _createElementVNode("div", null, [
              _createElementVNode("svg", {
                class: "animate-spin -ml-1 mr-3 h-5 w-5",
                xmlns: "http://www.w3.org/2000/svg",
                fill: "none",
                viewBox: "0 0 24 24"
              }, [
                _createElementVNode("circle", {
                  class: "opacity-25",
                  cx: "12",
                  cy: "12",
                  r: "10",
                  stroke: "currentColor",
                  "stroke-width": "4"
                }),
                _createElementVNode("path", {
                  class: "opacity-75",
                  fill: "currentColor",
                  d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                })
              ])
            ]),
            _createElementVNode("div", null, "Loading links..")
          ], -1)
        ])))
      : _createCommentVNode("", true),
    ($props.items.length === 0 && !$props.isLoading)
      ? (_openBlock(), _createElementBlock("li", _hoisted_3, _cache[1] || (_cache[1] = [
          _createElementVNode("div", { class: "px-4 py-6 sm:px-8 text-center" }, "No urls found", -1)
        ])))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: item.linkId
      }, [
        ($props.items.length !== 0 && !$props.isLoading)
          ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
              _createVNode(_component_UrlListItem, {
                item: item,
                items: $props.items
              }, null, 8, ["item", "items"])
            ]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}