import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "create-new-url" }
const _hoisted_2 = { class: "md:col-span-3" }
const _hoisted_3 = { class: "md:col-span-2" }
const _hoisted_4 = { class: "mb-6" }
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = {
  key: 0,
  class: "flex ml-auto",
  style: {"align-items":"center"}
}
const _hoisted_7 = { class: "mt-1 relative rounded-md" }
const _hoisted_8 = {
  key: 0,
  class: "text-red-500 text-xs italic mt-1"
}
const _hoisted_9 = {
  key: 1,
  class: "text-yellow-500 text-xs italic mt-1"
}
const _hoisted_10 = { class: "mb-6" }
const _hoisted_11 = {
  for: "tag-name",
  class: "block text-sm font-medium leading-5 text-gray-700 flex items-center"
}
const _hoisted_12 = { class: "mt-1 relative rounded-md" }
const _hoisted_13 = {
  key: 0,
  class: "text-red-500 text-xs italic mt-1"
}
const _hoisted_14 = {
  key: 1,
  class: "text-red-500 text-xs italic mt-1"
}
const _hoisted_15 = {
  key: 2,
  class: "text-gray-500 text-xs italic mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputUrlForm = _resolveComponent("InputUrlForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => ($options.shortenUrl && $options.shortenUrl(...args)), ["prevent"])),
      class: "grid grid-cols-1 gap-y-6 md:grid-cols-6 sm:gap-x-8"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_InputUrlForm)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[7] || (_cache[7] = _createElementVNode("label", {
              for: "short-name",
              class: "block text-sm font-medium leading-5 text-gray-700 pb-1"
            }, " Short name ", -1)),
            (this.nextName)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _cache[6] || (_cache[6] = _createStaticVNode("<div class=\"mr-2\"><svg width=\"9px\" height=\"16px\" viewBox=\"0 0 9 16\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\"><g id=\"Page-1\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"><g id=\"bolt-thunder-svgrepo-com\" fill=\"#5145cd\" fill-rule=\"nonzero\"><path d=\"M8.70122417,4.82576218 C8.56467836,4.55909552 8.29030799,4.391423 7.99061209,4.391423 L6.33596881,4.391423 L7.54348538,1.07125146 C7.63237427,0.826385965 7.59644444,0.553606238 7.4471423,0.340397661 C7.29755945,0.126939571 7.05350487,0 6.79295127,0 L2.40152827,0 C2.03505653,0 1.71568031,0.249387914 1.62704094,0.604693957 L0.0301598441,6.99221832 C-0.0294424951,7.23069006 0.0240467836,7.48353996 0.175469786,7.67728655 C0.326643275,7.87103314 0.558721248,7.98440546 0.804647173,7.98440546 L2.47391813,7.98440546 L0.0511812865,14.9066355 C-0.0800311891,15.2810916 0.0839298246,15.6952203 0.435773879,15.8786121 C0.553138402,15.9395556 0.679298246,15.9688421 0.804116959,15.9688421 C1.05403509,15.9688421 1.29808967,15.8514776 1.45244444,15.6369591 L8.63840936,5.65645224 C8.81378558,5.41317739 8.83801949,5.09246004 8.70122417,4.82576218 Z M0.804647173,15.1703704 L3.59918908,7.18596491 L0.804647173,7.18596491 L2.40152827,0.798440546 L6.79295127,0.798440546 L5.19607018,5.18986355 L7.99061209,5.18986355 L0.804647173,15.1703704 Z\" id=\"Shape\"></path></g></g></svg></div>", 1)),
                  _createElementVNode("div", {
                    class: "text-sm text-indigo-700 cursor-pointer",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.pickShortName && $options.pickShortName(...args)))
                  }, " Pick \"" + _toDisplayString(_ctx.nextName) + "\" ", 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _withDirectives(_createElementVNode("input", {
              id: "short-name",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.shortName) = $event)),
              class: "form-input py-3 px-4 block w-full transition ease-in-out duration-150",
              placeholder: "etc.: sale, offer25, 8754e1 .."
            }, null, 512), [
              [_vModelText, _ctx.shortName]
            ])
          ]),
          (this.errors.shortName)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.errors.shortName), 1))
            : _createCommentVNode("", true),
          (this.warnings.shortName)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.warnings.shortName), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("label", _hoisted_11, [
            _cache[9] || (_cache[9] = _createElementVNode("span", { class: "mr-1" }, "Tag (optional)", -1)),
            _createElementVNode("span", {
              onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => ($options.toggleHelp && $options.toggleHelp(...args)), ["prevent"]))
            }, _cache[8] || (_cache[8] = [
              _createElementVNode("svg", {
                class: "h-5 w-5 text-gray-600",
                xmlns: "http://www.w3.org/2000/svg",
                fill: "none",
                viewBox: "0 0 24 24",
                stroke: "currentColor"
              }, [
                _createElementVNode("path", {
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  "stroke-width": "2",
                  d: "M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                })
              ], -1)
            ]))
          ]),
          _createElementVNode("div", _hoisted_12, [
            _withDirectives(_createElementVNode("input", {
              id: "tag-name",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.tagName) = $event)),
              onBlur: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => ($options.handleTagNameBlur && $options.handleTagNameBlur(...args))),
              class: "form-input py-3 px-4 block w-full transition ease-in-out duration-150",
              placeholder: "video id, FB post id.."
            }, null, 544), [
              [_vModelText, _ctx.tagName]
            ])
          ]),
          (this.errors.tagName)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.errors.tagName), 1))
            : _createCommentVNode("", true),
          (this.errors.shortAndTagNames)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_ctx.errors.shortAndTagNames), 1))
            : _createCommentVNode("", true),
          (_ctx.tagHelpVisible)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _cache[10] || (_cache[10] = _createTextVNode(" Provide tag to use same short name.")),
                _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
                _cache[12] || (_cache[12] = _createTextVNode(" Etc.: ")),
                _createElementVNode("b", null, _toDisplayString(_ctx.shortenerDomain) + "/Nord-Sale/123", 1),
                _cache[13] || (_cache[13] = _createTextVNode(" and ")),
                _createElementVNode("b", null, _toDisplayString(_ctx.shortenerDomain) + "/Nord-Sale/xyz", 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _cache[14] || (_cache[14] = _createElementVNode("div", null, [
        _createElementVNode("span", { class: "md:mt-6 w-full inline-flex rounded-md" }, [
          _createElementVNode("button", {
            type: "submit",
            class: "w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-500 focus:outline-none focus:border-green-700 focus:ring-green active:bg-emerald-700 transition ease-in-out duration-150"
          }, " Shorten ")
        ])
      ], -1))
    ], 32)
  ]))
}