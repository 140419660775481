import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from "./views/Home.vue";
import Config from "./views/Config.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        component: Home,
    },
    {
        path: "/config",
        name: "config",
        component: Config,
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
