<template>
    <div class="input-url-form">
        <div class="flex mb-4">
            <div class="flex items-center mr-4">
                <input
                    :id="'custom-url_' + linkId"
                    :name="'url_type' + linkId"
                    type="radio"
                    value="custom_url"
                    v-model="urlType"
                    class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                />
                <label :for="'custom-url_' + linkId" class="ml-3">
                    <span
                        class="block text-sm leading-5 font-medium text-gray-700"
                        >Add custom URL</span
                    >
                </label>
            </div>
            <div class="flex items-center">
                <input
                    :id="'custom-url-utm_' + linkId"
                    :name="'url_type' + linkId"
                    type="radio"
                    value="custom_url_utm"
                    v-model="urlType"
                    class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                />
                <label :for="'custom-url-utm_' + linkId" class="ml-3">
                    <span
                        class="block text-sm leading-5 font-medium text-gray-700"
                        >Build URL with UTM tags</span
                    >
                </label>
            </div>
        </div>
        <div class="mb-4">
            <div class="mt-1 relative rounded-md">
                <input
                    :id="'url-source_' + linkId"
                    v-model="urlSource"
                    class="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                    placeholder="Shorten your link (input)"
                    type="url"
                    data-test="url-source"
                />
            </div>
            <div
                v-if="this.errors.urlSource"
                class="text-red-500 text-xs italic mt-1"
            >
                {{ errors.urlSource }}
            </div>
        </div>

        <div
            class="create-new-url__utm-tags py-4 px-4 border-2 border-indigo-300 border-dashed mb-4"
            v-if="urlType === 'custom_url_utm'"
        >
            <h2 class="mb-4">Campaign information</h2>
            <div class="grid grid-cols-1 gap-y-4 gap-x-4 md:grid-cols-2">
                <div>
                    <label
                        :for="'utm-source_' + linkId"
                        class="block text-sm font-medium leading-5 text-gray-700"
                    >
                        Source <span class="text-red-500">*</span>
                    </label>
                    <div class="mt-1 rounded-md shadow-sm">
                        <input
                            :id="'utm-source_' + linkId"
                            v-model="utmSource"
                            class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            type="text"
                            placeholder="The referrer: (e.g. google, bing)"
                        />
                    </div>
                    <div
                        v-if="this.errors.utmSource"
                        class="text-red-500 text-xs mt-1"
                    >
                        {{ errors.utmSource }}
                    </div>
                </div>
                <div>
                    <label
                        :for="'utm-medium_' + linkId"
                        class="block text-sm font-medium leading-5 text-gray-700"
                    >
                        Medium <span class="text-red-500">*</span>
                    </label>
                    <div class="mt-1 rounded-md shadow-sm">
                        <input
                            :id="'utm-medium_' + linkId"
                            v-model="utmMedium"
                            class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            type="text"
                            placeholder="Marketing medium: (e.g. cpc, banner, email)"
                        />
                    </div>
                    <div
                        v-if="this.errors.utmMedium"
                        class="text-red-500 text-xs mt-1"
                    >
                        {{ errors.utmMedium }}
                    </div>
                </div>
                <div>
                    <label
                        :for="'utm-campaign_' + linkId"
                        class="block text-sm font-medium leading-5 text-gray-700"
                    >
                        Name <span class="text-red-500">*</span>
                    </label>
                    <div class="mt-1 rounded-md shadow-sm">
                        <input
                            :id="'utm-campaign_' + linkId"
                            v-model="utmCampaign"
                            class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            type="text"
                            placeholder="Campaign name: (e.g. ' Search - US - BMM')"
                        />
                    </div>
                    <div
                        v-if="this.errors.utmCampaign"
                        class="text-red-500 text-xs mt-1"
                    >
                        {{ errors.utmCampaign }}
                    </div>
                </div>
                <div>
                    <label
                        :for="'utm-term_' + linkId"
                        class="block text-sm font-medium leading-5 text-gray-700"
                    >
                        Term
                    </label>
                    <div class="mt-1 rounded-md shadow-sm">
                        <input
                            :id="'utm-term_' + linkId"
                            v-model="utmTerm"
                            class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            type="text"
                            placeholder="Identify the paid keywords"
                        />
                    </div>
                </div>
                <div>
                    <label
                        :for="'utm-content_' + linkId"
                        class="block text-sm font-medium leading-5 text-gray-700"
                    >
                        Content
                    </label>
                    <div class="mt-1 rounded-md shadow-sm">
                        <input
                            :id="'utm-content_' + linkId"
                            v-model="utmContent"
                            class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            type="text"
                            placeholder="Use to differentiate ads"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="py-4 px-4 mt-4 border-2 border-indigo-300 border-dashed">
            <h2 class="">Parameters</h2>
            <div v-for="item in customParameters" :key="item.linkId">
                <KeyValueInput :item="item" />
            </div>
            <div class="mt-4">
                <a
                    href="#"
                    @click.prevent="addCustomParameter()"
                    class="font-medium text-sm text-gray-600 hover:text-indigo-500"
                    data-test="add-custom-parameter"
                >
                    + Add
                </a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { validateLinkAndDomain, validateRequired } from "../common/validators";
import store from "../store";
import KeyValueInput from "@/components/KeyValueInput.vue";

export default {
    name: "InputUrlForm",
    props: {
        item: Object,
    },
    components: {
        KeyValueInput,
    },
    data: function () {
        return {
            // Form fields
            linkId: "new", // default for create new form
            urlType: "", // custom_url | custom_url_utm
            urlSource: "",

            // UTM fields
            utmSource: "",
            utmMedium: "",
            utmCampaign: "",
            utmTerm: "",
            utmContent: "",

            // Custom fields
            customParameters: [],

            // Additional
            errors: {},
            valid: true,
        };
    },
    methods: {
        validateForm() {
            const validUrl = validateLinkAndDomain(this.urlSource);
            this.errors.urlSource = validUrl.error;

            if (this.urlType === "custom_url_utm") {
                // UTM field validation
                const validUtmSource = validateRequired(this.utmSource);
                this.errors.utmSource = validUtmSource.error;

                const validUtmMedium = validateRequired(this.utmMedium);
                this.errors.utmMedium = validUtmMedium.error;

                const validUtmCampaign = validateRequired(this.utmCampaign);
                this.errors.utmCampaign = validUtmCampaign.error;

                this.valid =
                    validUrl.valid &&
                    validUtmSource.valid &&
                    validUtmMedium.valid &&
                    validUtmCampaign.valid;
            } else {
                this.valid = validUrl.valid;

                // Make sure we clear UTM fields
                this.utmSource = "";
                this.utmMedium = "";
                this.utmCampaign = "";
                this.utmTerm = "";
                this.utmContent = "";
            }

            // Trigger UI update
            this.$forceUpdate();

            // Fire callback
            this.$emitter.emit(`utmFormCallBack-${this.linkId}`, {
                valid: this.valid,
                fields: {
                    urlSource: this.urlSource,
                    utmSource: this.utmSource,
                    utmMedium: this.utmMedium,
                    utmCampaign: this.utmCampaign,
                    utmTerm: this.utmTerm,
                    utmContent: this.utmContent,
                    customParameters: JSON.parse(
                        JSON.stringify(this.customParameters)
                    ),
                },
            });
        },
        formChanged(val, oldVal) {
            if (val && oldVal && val !== oldVal) {
                this.$emitter.emit(`utmFormChanged-${this.item.linkId}`, true);
            }
        },
        getDefaultUrl() {
            const externalDomains = store.getters.getExternalDomains;
            // Get first domain from config
            return externalDomains.length ? externalDomains[0] : "";
        },
        addCustomParameter() {
            this.customParameters.push({
                id: uuidv4(),
                key: "",
                value: "",
            });
        },
    },
    created() {
        // Copy item for edit mode
        if (this.item) {
            this.linkId = this.item.linkId;
            // Show utm form if at least one utm is stored
            this.urlType =
                this.item.utmSource ||
                this.item.utmMedium ||
                this.item.utmCampaign ||
                this.item.utmTerm ||
                this.item.utmContent
                    ? "custom_url_utm"
                    : "custom_url";
            this.urlSource = this.item.url;

            // UTM fields
            this.utmSource = this.item.utmSource || "";
            this.utmMedium = this.item.utmMedium || "";
            this.utmCampaign = this.item.utmCampaign || "";
            this.utmTerm = this.item.utmTerm || "";
            this.utmContent = this.item.utmContent || "";

            // Custom params
            this.customParameters = this.item.customParameters || [];
        } else {
            this.urlType = "custom_url";
        }

        // Register events
        this.$emitter.on(`validateUtmForm-${this.linkId}`, () => {
            this.validateForm();
        });

        this.$emitter.on(`clearUtmForm-${this.linkId}`, () => {
            this.urlType = "custom_url";
            this.urlSource = this.getDefaultUrl();
            this.utmSource = "";
            this.utmMedium = "";
            this.utmCampaign = "";
            this.utmTerm = "";
            this.utmContent = "";
            this.customParameters = [];
        });

        // Change URL source
        this.$emitter.on(`setUrlTypeToUtm`, () => {
            this.urlType = "custom_url_utm";
        });

        // Prefill UTM term from Tag
        this.$emitter.on(`setUrlSource`, (defaultUrl) => {
            // Prefill only when field is empty
            if (this.urlSource == "") {
                this.urlSource = defaultUrl;
            }
        });

        this.$emitter.on(`updateCustomParameter`, (item) => {
            const index = _.indexOf(
                this.customParameters,
                _.find(this.customParameters, { id: item.id })
            );
            this.customParameters[index] = item;
        });

        this.$emitter.on(`removeCustomParameter`, (id) => {
            if (this.customParameters) {
                this.customParameters.splice(
                    _.indexOf(
                        this.customParameters,
                        _.find(this.customParameters, { id: id })
                    ),
                    1
                );
            }
        });

        this.$emitter.on("fillInputForm", (item) => {
            this.urlSource = item.url;
            this.utmSource = item.utmSource;
            this.utmMedium = item.utmMedium;
            this.utmCampaign = item.utmCampaign;
            this.utmTerm = item.utmTerm;
            this.utmContent = item.utmContent;
            this.customParameters = item.customParameters;
        });
    },
    unmounted() {
        // Unregister listeners
        this.$emitter.off(`validateUtmForm-${this.linkId}`);
        this.$emitter.off(`clearUtmForm-${this.linkId}`);
        this.$emitter.off(`setUrlTypeToUtm`);
        this.$emitter.off(`setUrlSource`);
        this.$emitter.off(`updateCustomParameter`);
        this.$emitter.off(`removeCustomParameter`);
        this.$emitter.off(`fillInputForm`);
    },
};
</script>
