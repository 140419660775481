<template>
    <div class="home">
        <TopMenu></TopMenu>
        <div class="mt-4 mb-12 md:mb-16">
            <div class="flex items-center">
                <div class="md:border-r pr-4 mr-4" v-if="logoUrl">
                    <img
                        :src="logoUrl"
                        style="max-width: 150px; max-height: 30px"
                    />
                </div>
                <h2 class="text-xl md:text-3xl text-gray-800 font-bold">
                    go.vpnleaker.com affiliate link maker
                </h2>
            </div>
        </div>
        <CreateNewUrl :items="links" class="mb-12 md:mb-15" />
        <input
            id="search"
            v-model="listFilter"
            class="form-input py-3 px-4 block w-full sm:w-1/4 mb-4"
            type="search"
            placeholder="Search saved rules"
        />
        <div class="pl-4 mb-4 text-sm leading-5 font-medium text-gray-700">
            Total results: {{ totalResults }}
        </div>
        <UrlList :items="displayedLinks" :isLoading="loading" />
        <nav
            class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0 mb-5"
            v-if="pages.length > 1"
        >
            <div class="-mt-px w-0 flex-1 flex">
                <a
                    href="#"
                    v-if="page !== 1"
                    @click.prevent="openPage(1, 'prev', listFilter)"
                    class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                >
                    ← Previous
                </a>
            </div>
            <div class="hidden md:-mt-px md:flex">
                <a
                    href="#"
                    v-for="pageNumber in getPageRange()"
                    :key="pageNumber"
                    @click.prevent="openPage(pageNumber, '', listFilter)"
                    :class="getPageLinkStyle(pageNumber)"
                >
                    {{ pageNumber }}
                </a>
            </div>
            <div class="text-sm text-gray-500 pt-4 ml-5">
                total pages: {{ totalPages }}
            </div>
            <div class="-mt-px w-0 flex-1 flex justify-end">
                <a
                    href="#"
                    @click.prevent="openPage(1, 'next', listFilter)"
                    v-if="page < pages.length"
                    class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                >
                    Next →
                </a>
            </div>
        </nav>

        <DuplicateRulePopup :items="links" />
    </div>
</template>

<script lang="ts">
// @ is an alias to /src
import * as _ from "lodash";
import $backend from "../backend";
import store from "../store";
import TopMenu from "../components/TopMenu.vue";
import CreateNewUrl from "../components/CreateNewUrl.vue";
import UrlList from "../components/UrlList.vue";
import DuplicateRulePopup from "../components/DuplicateRulePopup.vue";

export default {
    name: "home-page",
    components: {
        TopMenu,
        CreateNewUrl,
        UrlList,
        DuplicateRulePopup,
    },
    data: function () {
        return {
            links: [],
            linksInitial: [],
            page: 1,
            perPage: 25,
            pages: [],
            listFilter: "",
            savingLink: false,
            logoUrl: store.getters.getLogo,
            isLoading: false,
            totalPages: 1,
            totalResults: 0,
            timer: 0,
        };
    },
    computed: {
        loading() {
            return this.isLoading || this.savingLink;
        },
        displayedLinks() {
            return this.paginate(this.links);
        },
    },
    methods: {
        fetch(pageNumber, q) {
            this.isLoading = true;

            $backend
                .fetchLinks(pageNumber, q)
                .then((data) => {
                    if (data.error) {
                        console.error("error: " + data.error);
                    } else {
                        this.links = updateDateTimeString(data.data);
                        this.linksInitial = JSON.parse(
                            JSON.stringify(data.data)
                        );
                        this.page = data.page;
                        this.perPage = data.perPage;
                        this.totalPages = data.totalPages;
                        this.totalResults = data.totalResults;
                    }
                })
                .catch((error) => {
                    console.error("error: " + error.message);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        fetchConfig() {
            const externalDomains = store.getters.getExternalDomains;

            if (externalDomains.length === 0) {
                $backend
                    .fetchConfig()
                    .then((data) => {
                        if (data.error) {
                            console.error("error: " + data.error);
                            if (data.errorId === "no-target-domains") {
                                alert(
                                    "please save at least one target domain in Settings page"
                                );
                            }
                        } else if (data.allowedDomain.length) {
                            store.dispatch(
                                "setExternalDomains",
                                data.allowedDomain
                            );
                            this.$emitter.emit(
                                "setUrlSource",
                                data.allowedDomain[0]
                            );
                        }
                    })
                    .catch((error) => {
                        console.error("error: " + error.message);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        post(link) {
            this.savingLink = true;

            $backend
                .postLink(link)
                .then((response) => {
                    const lastRowId = response.last_row_id;

                    // Add link to list only if its new link
                    if (!link.linkId) {
                        // create
                        if (lastRowId) {
                            link.linkId = lastRowId;
                            this.$emitter.emit("createdLinkCallBack", link);
                            this.$toast.success("New link created");
                        } else {
                            this.$toast.error("Failed to save link.");
                        }
                    } else {
                        // update
                        this.$emitter.emit("updatedLinkCallBack", link);
                        this.$toast.success("Link updated");
                    }
                })
                .catch((error) => {
                    this.$toast.error("Failed to save link.");
                    this.$emitter.emit("apiCallBack-new", {
                        err: error.response.data,
                        shortName: link.shortName,
                    });
                })
                .finally(() => {
                    this.savingLink = false;
                });
        },
        setPages() {
            for (let index = 1; index <= this.totalPages; index++) {
                this.pages.push(index);
            }
        },
        paginate(links) {
            let from = 0;
            let to = this.perPage;
            return links.slice(from, to);
        },
        getPageLinkStyle(current) {
            if (current === this.page) {
                return "border-indigo-500 text-indigo-600 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium";
            } else {
                return '"border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium';
            }
        },
        getPageRange() {
            let maxPaginationLinks = 5;
            let groupIndex = Math.ceil(this.page / maxPaginationLinks);
            let from = groupIndex * maxPaginationLinks - maxPaginationLinks;
            let to = groupIndex * maxPaginationLinks;

            return this.pages.slice(from, to);
        },
        openPage(pageNumber = 1, direction = "", query = "") {
            switch (direction) {
                case "":
                    if (this.page !== pageNumber) {
                        this.fetch(pageNumber, query);
                    }
                    break;
                case "next":
                    this.fetch(this.page + 1, query);
                    break;
                case "prev":
                    this.fetch(this.page - 1, query);
                    break;
                default:
                    break;
            }
        },
        searchTimeOut(newVal, oldVal) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                if (newVal !== oldVal && newVal.length > 1) {
                    this.fetch(1, newVal);
                } else if (newVal !== oldVal && newVal.length === 0) {
                    this.fetch(1, newVal);
                }
            }, 800);
        },
    },
    created() {
        this.fetch();
        this.fetchConfig();

        // create
        this.$emitter.on("shortenUrl", (link) => {
            const newLink = {
                url: link.url,
                shortName: link.shortName,
                tagName: link.tagName,
                utmMedium: link.utmMedium,
                utmCampaign: link.utmCampaign,
                utmTerm: link.utmTerm,
                utmContent: link.utmContent,
                utmSource: link.utmSource,
                customParameters: link.customParameters,
                created: new Date().toISOString(),
                updated: null,
            };

            // Post to API
            this.post(newLink);
        });

        this.$emitter.on("createdLinkCallBack", (newLink) => {
            this.links.unshift(newLink);
        });

        this.$emitter.on("updatedLinkCallBack", (link) => {
            const index = _.findIndex(this.links, { linkId: link.linkId });
            this.links[index] = link;
        });

        // update
        this.$emitter.on("postLink", (link) => {
            this.post(link);
        });

        // delete
        this.$emitter.on("deleteUrl", (item) => {
            if (confirm("Do you really want to delete this link?")) {
                this.links.splice(
                    _.indexOf(
                        this.links,
                        _.find(this.links, { linkId: item.linkId })
                    ),
                    1
                );
                this.linksInitial.splice(
                    _.indexOf(
                        this.linksInitial,
                        _.find(this.linksInitial, { linkId: item.linkId })
                    ),
                    1
                );
                $backend
                    .removeLink(item)
                    .then(() => {
                        this.$toast.success("Link deleted");
                    })
                    .catch(() => {
                        this.$toast.error("Failed to delete link");
                    });
            }
        });
    },
    watch: {
        displayedLinks() {
            this.pages = [];
            this.setPages();
        },
        // search fetch
        listFilter: function (newVal, oldVal) {
            this.searchTimeOut(newVal, oldVal);
        },
    },
    unmounted() {
        // Unregister listeners
        this.$emitter.off("shortenUrl");
        this.$emitter.off("createdLinkCallBack");
        this.$emitter.off("updatedLinkCallBack");
        this.$emitter.off("postLink");
        this.$emitter.off("deleteUrl");
    },
};

const updateDateTimeString = (links) => {
    let result = [];

    for (let link of links) {
        link.created = convertTimestampToDate(link.created);
        link.updated = convertTimestampToDate(link.updated);
        link.customParameters = link.customParameters
            ? link.customParameters
            : [];
        result.push(link);
    }

    return result;
};

const convertTimestampToDate = (timestamp) => {
    if (timestamp) {
        return new Date(timestamp).toISOString();
    } else {
        return null;
    }
};
</script>
