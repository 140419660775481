import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "custom-parameters-form grid grid-cols-1 gap-y-4 gap-x-4 md:grid-cols-3 mt-4" }
const _hoisted_2 = { class: "mt-1 relative rounded-md" }
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "mt-1 relative rounded-md" }
const _hoisted_5 = ["id"]
const _hoisted_6 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          id: 'input-key_' + $props.item.id,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.key) = $event)),
          onChange: _cache[1] || (_cache[1] = ($event: any) => ($options.updateItem())),
          class: "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",
          placeholder: "Parameter name",
          type: "text",
          "data-test": "input-key"
        }, null, 40, _hoisted_3), [
          [_vModelText, _ctx.key]
        ])
      ])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          id: 'input-value_' + $props.item.id,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value) = $event)),
          onChange: _cache[3] || (_cache[3] = ($event: any) => ($options.updateItem())),
          class: "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",
          placeholder: "Value",
          type: "text"
        }, null, 40, _hoisted_5), [
          [_vModelText, _ctx.value]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => ($options.deleteItem($props.item.id)), ["prevent"])),
        class: "font-medium text-sm text-gray-600 hover:text-indigo-500"
      }, " Remove ")
    ])
  ]))
}