import Vuex from "vuex";

const store = new Vuex.Store({
    state: {
        externalDomains: [],
        envVariables: {
            api: "",
            domain: "",
            logo: "",
            featureAddSuffix: false,
        },
    },
    mutations: {
        mutateExternalDomains(state, externalDomains) {
            state.externalDomains = externalDomains;
        },
        mutateEnvVariables(state, config) {
            state.envVariables = config;
        },
    },
    actions: {
        setExternalDomains(context, externalDomains) {
            context.commit("mutateExternalDomains", externalDomains);
        },
        setEnvVariables(context, config) {
            context.commit("mutateEnvVariables", config);
        },
    },
    getters: {
        getExternalDomains(state) {
            return state.externalDomains;
        },
        getApiEndpoint(state) {
            return state.envVariables.api;
        },
        getShortenerDomain(state) {
            return state.envVariables.domain;
        },
        getLogo(state) {
            return state.envVariables.logo || "/default-logo.png";
        },
        getFeatureAddSuffix(state) {
            return state.envVariables.featureAddSuffix;
        },
    },
});

export default store;
