import axios from "axios";
import {
    getApiEndpoint,
    readEnvVariables,
    removeEmptyProperties,
} from "./utils";

// Read/store env variables
readEnvVariables();

const axiosSettings = {
    timeout: 30000,
    headers: { "Content-Type": "application/json" },
    baseURL: getApiEndpoint(),
};

const $axios = axios.create(axiosSettings);

// Response Interceptor to handle and log errors
$axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        // Handle Error
        console.log(error);
        return Promise.reject(error);
    }
);

export default {
    fetchLinks(pageNumber = 1, q = "") {
        return $axios
            .get(`links/`, { params: { page: pageNumber, q: q } })
            .then((response) => response.data);
    },

    postLink(link) {
        const payload = removeEmptyProperties(link);

        return $axios.post(`links/`, payload).then((response) => response.data);
    },
    removeLink(link) {
        return $axios
            .delete(`links/`, {
                data: link,
            })
            .then((response) => response.data);
    },
    fetchConfig() {
        return $axios.get(`config/`).then((response) => response.data);
    },
    postConfig(data) {
        return $axios.post(`config/`, data).then((response) => response.data);
    },
};
