import store from "./store";
import * as _ from "lodash";

const getApiEndpoint = () => {
    let endPointUrl = null;

    if (store.getters.getApiEndpoint) {
        // Support endpoint without protocol
        let apiUrlObj = null;
        try {
            apiUrlObj = new URL(store.getters.getApiEndpoint);
        } catch (err) {
            apiUrlObj = new URL(`https://${store.getters.getApiEndpoint}`);
        }

        if (apiUrlObj) {
            endPointUrl = apiUrlObj.href;
        }
    }

    return endPointUrl;
};

const readEnvVariables = () => {
    try {
        const value = process.env.VUE_APP_SETTINGS;

        if (!value) {
            window.alert(`Could not find config variable`);
        } else {
            // Save config
            store.dispatch("setEnvVariables", JSON.parse(value));
        }
    } catch (ex) {
        window.alert(`Exception thrown reading config variable: ${ex}`);
    }
};

const getShortUrl = (shortName, tagName) => {
    const shortenerDomain = store.getters.getShortenerDomain;
    if (tagName) {
        return `https://${shortenerDomain}/${shortName}/${tagName}/`;
    } else {
        return `https://${shortenerDomain}/${shortName}/`;
    }
};

function getGeneratedName(shortName, items) {
    const separators = ["_", "-"];

    try {
        // start check after 3 symbols
        if (shortName.length < 3) return null;

        // Find items from which you can extract index
        const similar = [];
        for (const item of items) {
            const index = getIndexFromName(
                shortName,
                item.shortName,
                separators
            );
            if (index || index === 0) {
                // Collect extracted indexes
                similar.push({
                    shortName: item.shortName,
                    index: index,
                });
            }
        }

        if (similar.length > 0) {
            const item = _.maxBy(similar, function (o) {
                return o.index;
            });
            const nextName = item.shortName.replace(item.index, item.index + 1);
            return nextName;
        }
    } catch (e) {
        console.log("error", e);
    }

    return null;
}

function getIndexFromName(input, name, separators) {
    for (const sep of separators) {
        const prefix = `${input}${sep}`;
        const numberPart = name.replace(prefix, "");
        const num = Number(numberPart);
        if (num || num === 0) return num;
    }

    return null;
}

function removeEmptyProperties(obj) {
    const skipKeys = ["created", "updated"];

    return Object.keys(obj).reduce((result, key) => {
        if (
            obj[key] === null ||
            obj[key] === undefined ||
            obj[key] === "" ||
            (Array.isArray(obj[key]) && obj[key].length === 0) ||
            skipKeys.includes(key)
        ) {
            // skip
        } else {
            result[key] = obj[key];
        }

        return result;
    }, {});
}

export {
    getApiEndpoint,
    readEnvVariables,
    getShortUrl,
    getGeneratedName,
    removeEmptyProperties,
};
