<template>
    <div class="fixed z-10 inset-0 overflow-y-auto" v-if="isVisible">
        <div
            class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
            <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <!-- This element is to trick the browser into centering the modal contents. -->
            <span
                class="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
                >&#8203;</span
            >
            <div
                class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
            >
                <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div class="sm:flex sm:items-start">
                        <div
                            class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"
                        >
                            <svg
                                class="h-6 w-6 text-gray-600"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                                />
                            </svg>
                        </div>
                        <div
                            class="flex-grow mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left"
                        >
                            <h3
                                class="text-lg leading-6 font-medium text-gray-900 mt-2 mb-4"
                                id="modal-headline"
                            >
                                Duplicate rule
                            </h3>
                            <p class="text-sm text-gray-500 mb-6">
                                Change short name or enter new tag.
                            </p>
                            <div class="mb-6">
                                <label
                                    for="short_name_cloned"
                                    class="block text-sm font-medium leading-5 text-gray-700"
                                >
                                    Short name
                                </label>
                                <div class="mt-2">
                                    <input
                                        id="short_name_cloned"
                                        v-model="shortName"
                                        class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                        type="text"
                                        placeholder="etc.: sale, offer25, 8754e1 .."
                                    />
                                </div>
                                <div
                                    v-if="this.errors.shortName"
                                    class="text-red-500 text-xs italic mt-1"
                                >
                                    {{ errors.shortName }}
                                </div>
                            </div>
                            <div class="mb-6">
                                <label
                                    for="tag_name_cloned"
                                    class="block text-sm font-medium leading-5 text-gray-700"
                                >
                                    Tag (optional)
                                </label>
                                <div class="mt-2">
                                    <input
                                        id="tag_name_cloned"
                                        v-model="tagName"
                                        class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                        type="text"
                                        placeholder="video id, FB post id.."
                                    />
                                </div>
                                <div
                                    v-if="this.errors.tagName"
                                    class="text-red-500 text-xs italic mt-1"
                                >
                                    {{ errors.tagName }}
                                </div>
                                <div
                                    v-if="this.errors.shortAndTagNames"
                                    class="text-red-500 text-xs italic mt-1"
                                >
                                    {{ errors.shortAndTagNames }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
                >
                    <button
                        type="button"
                        @click.prevent="save"
                        class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-emerald-600 text-base font-medium text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                        Duplicate
                    </button>
                    <button
                        type="button"
                        @click.prevent="cancel"
                        class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {
    validateShortAndTagNames,
    validateShortName,
    validateTagName,
} from "../common/validators";

export default {
    name: "DuplicateRulePopup",
    props: {
        items: Array,
    },
    data: function () {
        return {
            item: {},
            isVisible: false,

            // Form fields
            shortName: "",
            tagName: "",

            // Additional
            errors: {},
            valid: true,
        };
    },
    methods: {
        save() {
            this.errors = {};

            // General field validation
            const validShortName = validateShortName(
                this.shortName,
                this.items
            );
            this.errors.shortName = validShortName.error;

            const validTagName = validateTagName(this.tagName);
            this.errors.tagName = validTagName.error;

            if (this.shortName && this.tagName) {
                // Validate both short name and tag
                // Allow duplicate short names with unique tags
                const validShortAndTagNames = validateShortAndTagNames(
                    this.shortName,
                    this.tagName,
                    this.items
                );
                this.errors.shortAndTagNames = validShortAndTagNames.error;

                // Allow non unique short names when tag is used
                if (validShortName.rule == "unique") {
                    validShortName.valid = true;
                    this.errors.shortName = null;
                }

                this.valid =
                    validShortName.valid &&
                    validTagName.valid &&
                    validShortAndTagNames.valid;
            } else {
                // Own fields
                this.valid = validShortName.valid;
            }

            if (this.valid) {
                const clonedItem = {
                    url: this.item.url,
                    shortName: this.shortName,
                    tagName: this.tagName || null,
                    utmSource: this.item.utmSource || null,
                    utmMedium: this.item.utmMedium || null,
                    utmCampaign: this.item.utmCampaign || null,
                    utmTerm: this.tagName || this.item.utmTerm || null,
                    utmContent: this.item.utmContent || null,
                    customParameters: this.item.customParameters || null,
                };
                this.$emitter.emit("cloneItem", clonedItem);

                // close modal
                this.isVisible = false;
            }
        },
        cancel() {
            // close modal
            this.isVisible = false;
        },
    },
    created() {
        this.$emitter.on("openDuplicatePopup", (item) => {
            // clear fields
            this.tagName = "";
            this.errors = {};

            // show popup
            this.isVisible = true;

            // copy item data
            this.item = item;
            this.shortName = item.shortName;
            this.tagName = item.tagName;
        });
    },
    unmounted() {
        // Unregister listeners
        this.$emitter.off("openDuplicatePopup");
    },
};
</script>
