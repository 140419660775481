import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "mt-4 mb-12 md:mb-16" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = {
  key: 0,
  class: "md:border-r pr-4 mr-4"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "pl-4 mb-4 text-sm leading-5 font-medium text-gray-700" }
const _hoisted_7 = {
  key: 0,
  class: "border-t border-gray-200 px-4 flex items-center justify-between sm:px-0 mb-5"
}
const _hoisted_8 = { class: "-mt-px w-0 flex-1 flex" }
const _hoisted_9 = { class: "hidden md:-mt-px md:flex" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "text-sm text-gray-500 pt-4 ml-5" }
const _hoisted_12 = { class: "-mt-px w-0 flex-1 flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopMenu = _resolveComponent("TopMenu")!
  const _component_CreateNewUrl = _resolveComponent("CreateNewUrl")!
  const _component_UrlList = _resolveComponent("UrlList")!
  const _component_DuplicateRulePopup = _resolveComponent("DuplicateRulePopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TopMenu),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.logoUrl)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("img", {
                src: _ctx.logoUrl,
                style: {"max-width":"150px","max-height":"30px"}
              }, null, 8, _hoisted_5)
            ]))
          : _createCommentVNode("", true),
        _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "text-xl md:text-3xl text-gray-800 font-bold" }, " go.vpnleaker.com affiliate link maker ", -1))
      ])
    ]),
    _createVNode(_component_CreateNewUrl, {
      items: _ctx.links,
      class: "mb-12 md:mb-15"
    }, null, 8, ["items"]),
    _withDirectives(_createElementVNode("input", {
      id: "search",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.listFilter) = $event)),
      class: "form-input py-3 px-4 block w-full sm:w-1/4 mb-4",
      type: "search",
      placeholder: "Search saved rules"
    }, null, 512), [
      [_vModelText, _ctx.listFilter]
    ]),
    _createElementVNode("div", _hoisted_6, " Total results: " + _toDisplayString(_ctx.totalResults), 1),
    _createVNode(_component_UrlList, {
      items: $options.displayedLinks,
      isLoading: $options.loading
    }, null, 8, ["items", "isLoading"]),
    (_ctx.pages.length > 1)
      ? (_openBlock(), _createElementBlock("nav", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            (_ctx.page !== 1)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: "#",
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($options.openPage(1, 'prev', _ctx.listFilter)), ["prevent"])),
                  class: "border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                }, " ← Previous "))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getPageRange(), (pageNumber) => {
              return (_openBlock(), _createElementBlock("a", {
                href: "#",
                key: pageNumber,
                onClick: _withModifiers(($event: any) => ($options.openPage(pageNumber, '', _ctx.listFilter)), ["prevent"]),
                class: _normalizeClass($options.getPageLinkStyle(pageNumber))
              }, _toDisplayString(pageNumber), 11, _hoisted_10))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_11, " total pages: " + _toDisplayString(_ctx.totalPages), 1),
          _createElementVNode("div", _hoisted_12, [
            (_ctx.page < _ctx.pages.length)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: "#",
                  onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => ($options.openPage(1, 'next', _ctx.listFilter)), ["prevent"])),
                  class: "border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                }, " Next → "))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_DuplicateRulePopup, { items: _ctx.links }, null, 8, ["items"])
  ]))
}