import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed z-10 inset-0 overflow-y-auto"
}
const _hoisted_2 = { class: "flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" }
const _hoisted_3 = {
  class: "inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full",
  role: "dialog",
  "aria-modal": "true",
  "aria-labelledby": "modal-headline"
}
const _hoisted_4 = { class: "bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4" }
const _hoisted_5 = { class: "sm:flex sm:items-start" }
const _hoisted_6 = { class: "flex-grow mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left" }
const _hoisted_7 = { class: "mb-6" }
const _hoisted_8 = { class: "mt-2" }
const _hoisted_9 = {
  key: 0,
  class: "text-red-500 text-xs italic mt-1"
}
const _hoisted_10 = { class: "mb-6" }
const _hoisted_11 = { class: "mt-2" }
const _hoisted_12 = {
  key: 0,
  class: "text-red-500 text-xs italic mt-1"
}
const _hoisted_13 = {
  key: 1,
  class: "text-red-500 text-xs italic mt-1"
}
const _hoisted_14 = { class: "bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[9] || (_cache[9] = _createElementVNode("div", {
            class: "fixed inset-0 transition-opacity",
            "aria-hidden": "true"
          }, [
            _createElementVNode("div", { class: "absolute inset-0 bg-gray-500 opacity-75" })
          ], -1)),
          _cache[10] || (_cache[10] = _createElementVNode("span", {
            class: "hidden sm:inline-block sm:align-middle sm:h-screen",
            "aria-hidden": "true"
          }, "​", -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[8] || (_cache[8] = _createElementVNode("div", { class: "mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10" }, [
                  _createElementVNode("svg", {
                    class: "h-6 w-6 text-gray-600",
                    xmlns: "http://www.w3.org/2000/svg",
                    fill: "none",
                    viewBox: "0 0 24 24",
                    stroke: "currentColor"
                  }, [
                    _createElementVNode("path", {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "2",
                      d: "M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                    })
                  ])
                ], -1)),
                _createElementVNode("div", _hoisted_6, [
                  _cache[6] || (_cache[6] = _createElementVNode("h3", {
                    class: "text-lg leading-6 font-medium text-gray-900 mt-2 mb-4",
                    id: "modal-headline"
                  }, " Duplicate rule ", -1)),
                  _cache[7] || (_cache[7] = _createElementVNode("p", { class: "text-sm text-gray-500 mb-6" }, " Change short name or enter new tag. ", -1)),
                  _createElementVNode("div", _hoisted_7, [
                    _cache[4] || (_cache[4] = _createElementVNode("label", {
                      for: "short_name_cloned",
                      class: "block text-sm font-medium leading-5 text-gray-700"
                    }, " Short name ", -1)),
                    _createElementVNode("div", _hoisted_8, [
                      _withDirectives(_createElementVNode("input", {
                        id: "short_name_cloned",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.shortName) = $event)),
                        class: "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",
                        type: "text",
                        placeholder: "etc.: sale, offer25, 8754e1 .."
                      }, null, 512), [
                        [_vModelText, _ctx.shortName]
                      ])
                    ]),
                    (this.errors.shortName)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.errors.shortName), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _cache[5] || (_cache[5] = _createElementVNode("label", {
                      for: "tag_name_cloned",
                      class: "block text-sm font-medium leading-5 text-gray-700"
                    }, " Tag (optional) ", -1)),
                    _createElementVNode("div", _hoisted_11, [
                      _withDirectives(_createElementVNode("input", {
                        id: "tag_name_cloned",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tagName) = $event)),
                        class: "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",
                        type: "text",
                        placeholder: "video id, FB post id.."
                      }, null, 512), [
                        [_vModelText, _ctx.tagName]
                      ])
                    ]),
                    (this.errors.tagName)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.errors.tagName), 1))
                      : _createCommentVNode("", true),
                    (this.errors.shortAndTagNames)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.errors.shortAndTagNames), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("button", {
                type: "button",
                onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => ($options.save && $options.save(...args)), ["prevent"])),
                class: "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-emerald-600 text-base font-medium text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
              }, " Duplicate "),
              _createElementVNode("button", {
                type: "button",
                onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => ($options.cancel && $options.cancel(...args)), ["prevent"])),
                class: "mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              }, " Cancel ")
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}