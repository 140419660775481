<template>
    <div class="navigation">
        <router-link to="/" class="navigation-link border-r">
            <span class="text-xs">Home</span>
        </router-link>
        <router-link to="/config" class="navigation-link border-r">
            <span class="text-xs">Settings</span>
        </router-link>
    </div>
</template>

<style lang="scss">
.navigation {
    display: flex;
    justify-content: flex-start;

    &-link {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 5px;
        margin-right: 5px;
        padding-right: 5px;

        &:hover {
            span {
                opacity: 0.8;
            }
        }

        &:last-child {
            border-right: none;
        }

        span {
            opacity: 0.5;
        }
    }
}
</style>

<script lang="ts">
export default {
    name: "TopMenu",
};
</script>
