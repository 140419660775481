import { vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vModelText as _vModelText, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "input-url-form" }
const _hoisted_2 = { class: "flex mb-4" }
const _hoisted_3 = { class: "flex items-center mr-4" }
const _hoisted_4 = ["id", "name"]
const _hoisted_5 = ["for"]
const _hoisted_6 = { class: "flex items-center" }
const _hoisted_7 = ["id", "name"]
const _hoisted_8 = ["for"]
const _hoisted_9 = { class: "mb-4" }
const _hoisted_10 = { class: "mt-1 relative rounded-md" }
const _hoisted_11 = ["id"]
const _hoisted_12 = {
  key: 0,
  class: "text-red-500 text-xs italic mt-1"
}
const _hoisted_13 = {
  key: 0,
  class: "create-new-url__utm-tags py-4 px-4 border-2 border-indigo-300 border-dashed mb-4"
}
const _hoisted_14 = { class: "grid grid-cols-1 gap-y-4 gap-x-4 md:grid-cols-2" }
const _hoisted_15 = ["for"]
const _hoisted_16 = { class: "mt-1 rounded-md shadow-sm" }
const _hoisted_17 = ["id"]
const _hoisted_18 = {
  key: 0,
  class: "text-red-500 text-xs mt-1"
}
const _hoisted_19 = ["for"]
const _hoisted_20 = { class: "mt-1 rounded-md shadow-sm" }
const _hoisted_21 = ["id"]
const _hoisted_22 = {
  key: 0,
  class: "text-red-500 text-xs mt-1"
}
const _hoisted_23 = ["for"]
const _hoisted_24 = { class: "mt-1 rounded-md shadow-sm" }
const _hoisted_25 = ["id"]
const _hoisted_26 = {
  key: 0,
  class: "text-red-500 text-xs mt-1"
}
const _hoisted_27 = ["for"]
const _hoisted_28 = { class: "mt-1 rounded-md shadow-sm" }
const _hoisted_29 = ["id"]
const _hoisted_30 = ["for"]
const _hoisted_31 = { class: "mt-1 rounded-md shadow-sm" }
const _hoisted_32 = ["id"]
const _hoisted_33 = { class: "py-4 px-4 mt-4 border-2 border-indigo-300 border-dashed" }
const _hoisted_34 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KeyValueInput = _resolveComponent("KeyValueInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          id: 'custom-url_' + _ctx.linkId,
          name: 'url_type' + _ctx.linkId,
          type: "radio",
          value: "custom_url",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.urlType) = $event)),
          class: "form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
        }, null, 8, _hoisted_4), [
          [_vModelRadio, _ctx.urlType]
        ]),
        _createElementVNode("label", {
          for: 'custom-url_' + _ctx.linkId,
          class: "ml-3"
        }, _cache[9] || (_cache[9] = [
          _createElementVNode("span", { class: "block text-sm leading-5 font-medium text-gray-700" }, "Add custom URL", -1)
        ]), 8, _hoisted_5)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _withDirectives(_createElementVNode("input", {
          id: 'custom-url-utm_' + _ctx.linkId,
          name: 'url_type' + _ctx.linkId,
          type: "radio",
          value: "custom_url_utm",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.urlType) = $event)),
          class: "form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
        }, null, 8, _hoisted_7), [
          [_vModelRadio, _ctx.urlType]
        ]),
        _createElementVNode("label", {
          for: 'custom-url-utm_' + _ctx.linkId,
          class: "ml-3"
        }, _cache[10] || (_cache[10] = [
          _createElementVNode("span", { class: "block text-sm leading-5 font-medium text-gray-700" }, "Build URL with UTM tags", -1)
        ]), 8, _hoisted_8)
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _withDirectives(_createElementVNode("input", {
          id: 'url-source_' + _ctx.linkId,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.urlSource) = $event)),
          class: "form-input py-3 px-4 block w-full transition ease-in-out duration-150",
          placeholder: "Shorten your link (input)",
          type: "url",
          "data-test": "url-source"
        }, null, 8, _hoisted_11), [
          [_vModelText, _ctx.urlSource]
        ])
      ]),
      (this.errors.urlSource)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.errors.urlSource), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.urlType === 'custom_url_utm')
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _cache[14] || (_cache[14] = _createElementVNode("h2", { class: "mb-4" }, "Campaign information", -1)),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", null, [
              _createElementVNode("label", {
                for: 'utm-source_' + _ctx.linkId,
                class: "block text-sm font-medium leading-5 text-gray-700"
              }, _cache[11] || (_cache[11] = [
                _createTextVNode(" Source "),
                _createElementVNode("span", { class: "text-red-500" }, "*", -1)
              ]), 8, _hoisted_15),
              _createElementVNode("div", _hoisted_16, [
                _withDirectives(_createElementVNode("input", {
                  id: 'utm-source_' + _ctx.linkId,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.utmSource) = $event)),
                  class: "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",
                  type: "text",
                  placeholder: "The referrer: (e.g. google, bing)"
                }, null, 8, _hoisted_17), [
                  [_vModelText, _ctx.utmSource]
                ])
              ]),
              (this.errors.utmSource)
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(_ctx.errors.utmSource), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("label", {
                for: 'utm-medium_' + _ctx.linkId,
                class: "block text-sm font-medium leading-5 text-gray-700"
              }, _cache[12] || (_cache[12] = [
                _createTextVNode(" Medium "),
                _createElementVNode("span", { class: "text-red-500" }, "*", -1)
              ]), 8, _hoisted_19),
              _createElementVNode("div", _hoisted_20, [
                _withDirectives(_createElementVNode("input", {
                  id: 'utm-medium_' + _ctx.linkId,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.utmMedium) = $event)),
                  class: "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",
                  type: "text",
                  placeholder: "Marketing medium: (e.g. cpc, banner, email)"
                }, null, 8, _hoisted_21), [
                  [_vModelText, _ctx.utmMedium]
                ])
              ]),
              (this.errors.utmMedium)
                ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(_ctx.errors.utmMedium), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("label", {
                for: 'utm-campaign_' + _ctx.linkId,
                class: "block text-sm font-medium leading-5 text-gray-700"
              }, _cache[13] || (_cache[13] = [
                _createTextVNode(" Name "),
                _createElementVNode("span", { class: "text-red-500" }, "*", -1)
              ]), 8, _hoisted_23),
              _createElementVNode("div", _hoisted_24, [
                _withDirectives(_createElementVNode("input", {
                  id: 'utm-campaign_' + _ctx.linkId,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.utmCampaign) = $event)),
                  class: "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",
                  type: "text",
                  placeholder: "Campaign name: (e.g. ' Search - US - BMM')"
                }, null, 8, _hoisted_25), [
                  [_vModelText, _ctx.utmCampaign]
                ])
              ]),
              (this.errors.utmCampaign)
                ? (_openBlock(), _createElementBlock("div", _hoisted_26, _toDisplayString(_ctx.errors.utmCampaign), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("label", {
                for: 'utm-term_' + _ctx.linkId,
                class: "block text-sm font-medium leading-5 text-gray-700"
              }, " Term ", 8, _hoisted_27),
              _createElementVNode("div", _hoisted_28, [
                _withDirectives(_createElementVNode("input", {
                  id: 'utm-term_' + _ctx.linkId,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.utmTerm) = $event)),
                  class: "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",
                  type: "text",
                  placeholder: "Identify the paid keywords"
                }, null, 8, _hoisted_29), [
                  [_vModelText, _ctx.utmTerm]
                ])
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("label", {
                for: 'utm-content_' + _ctx.linkId,
                class: "block text-sm font-medium leading-5 text-gray-700"
              }, " Content ", 8, _hoisted_30),
              _createElementVNode("div", _hoisted_31, [
                _withDirectives(_createElementVNode("input", {
                  id: 'utm-content_' + _ctx.linkId,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.utmContent) = $event)),
                  class: "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",
                  type: "text",
                  placeholder: "Use to differentiate ads"
                }, null, 8, _hoisted_32), [
                  [_vModelText, _ctx.utmContent]
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_33, [
      _cache[15] || (_cache[15] = _createElementVNode("h2", { class: "" }, "Parameters", -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customParameters, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.linkId
        }, [
          _createVNode(_component_KeyValueInput, { item: item }, null, 8, ["item"])
        ]))
      }), 128)),
      _createElementVNode("div", _hoisted_34, [
        _createElementVNode("a", {
          href: "#",
          onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => ($options.addCustomParameter()), ["prevent"])),
          class: "font-medium text-sm text-gray-600 hover:text-indigo-500",
          "data-test": "add-custom-parameter"
        }, " + Add ")
      ])
    ])
  ]))
}