import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "border-b" }
const _hoisted_2 = { class: "list-item px-4 pt-6 pb-6 md:px-8 md:flex items-center relative" }
const _hoisted_3 = { class: "list-item__col list-item__col-a mb-2 md:mb-0" }
const _hoisted_4 = {
  key: 0,
  class: "break-all"
}
const _hoisted_5 = {
  key: 0,
  class: "text-sm text-gray-400"
}
const _hoisted_6 = { class: "text-gray-900" }
const _hoisted_7 = {
  key: 1,
  class: "text-sm text-gray-400"
}
const _hoisted_8 = { class: "text-gray-900" }
const _hoisted_9 = {
  key: 2,
  class: "text-sm text-gray-400"
}
const _hoisted_10 = { class: "text-gray-900" }
const _hoisted_11 = {
  key: 3,
  class: "text-sm text-gray-400"
}
const _hoisted_12 = { class: "text-gray-900" }
const _hoisted_13 = {
  key: 4,
  class: "text-sm text-gray-400"
}
const _hoisted_14 = { class: "text-gray-900" }
const _hoisted_15 = {
  key: 5,
  class: "text-sm text-gray-400"
}
const _hoisted_16 = { class: "text-gray-900" }
const _hoisted_17 = {
  key: 6,
  class: "text-sm text-gray-400"
}
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "list-item__col list-item__col-b" }
const _hoisted_20 = {
  key: 0,
  class: "md:flex items-center justify-end"
}
const _hoisted_21 = { class: "list-item__short-url" }
const _hoisted_22 = ["href"]
const _hoisted_23 = ["id", "value"]
const _hoisted_24 = { class: "flex" }
const _hoisted_25 = { class: "mt-3 rounded-md md:mt-0 md:ml-6" }
const _hoisted_26 = { class: "mt-3 md:mt-0 ml-3 md:ml-6 flex items-center" }
const _hoisted_27 = { class: "mt-3 md:mt-0 ml-3 md:ml-6 flex items-center" }
const _hoisted_28 = { class: "mt-3 md:mt-0 ml-3 md:ml-6 flex items-center" }
const _hoisted_29 = {
  key: 1,
  class: "md:flex items-center justify-end"
}
const _hoisted_30 = ["href"]
const _hoisted_31 = { class: "mt-3 rounded-md md:mt-0 md:ml-6" }
const _hoisted_32 = ["disabled"]
const _hoisted_33 = { class: "mt-3 md:mt-0 ml-3 md:ml-6 flex items-center" }
const _hoisted_34 = { class: "text-xs px-4 pb-4 md:px-8" }
const _hoisted_35 = {
  class: "text-gray-600",
  style: {"display":"inline-block"}
}
const _hoisted_36 = { key: 0 }
const _hoisted_37 = {
  class: "text-gray-600",
  style: {"display":"inline-block"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputUrlForm = _resolveComponent("InputUrlForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.editMode)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              ($props.item.url)
                ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
                    _cache[6] || (_cache[6] = _createTextVNode(" Url: ")),
                    _createElementVNode("span", _hoisted_6, _toDisplayString($props.item.url), 1)
                  ]))
                : _createCommentVNode("", true),
              ($props.item.utmCampaign)
                ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
                    _cache[7] || (_cache[7] = _createTextVNode(" Campaign: ")),
                    _createElementVNode("span", _hoisted_8, _toDisplayString($props.item.utmCampaign), 1)
                  ]))
                : _createCommentVNode("", true),
              ($props.item.utmContent)
                ? (_openBlock(), _createElementBlock("p", _hoisted_9, [
                    _cache[8] || (_cache[8] = _createTextVNode(" Content: ")),
                    _createElementVNode("span", _hoisted_10, _toDisplayString($props.item.utmContent), 1)
                  ]))
                : _createCommentVNode("", true),
              ($props.item.utmMedium)
                ? (_openBlock(), _createElementBlock("p", _hoisted_11, [
                    _cache[9] || (_cache[9] = _createTextVNode(" Medium: ")),
                    _createElementVNode("span", _hoisted_12, _toDisplayString($props.item.utmMedium), 1)
                  ]))
                : _createCommentVNode("", true),
              ($props.item.utmSource)
                ? (_openBlock(), _createElementBlock("p", _hoisted_13, [
                    _cache[10] || (_cache[10] = _createTextVNode(" Source: ")),
                    _createElementVNode("span", _hoisted_14, _toDisplayString($props.item.utmSource), 1)
                  ]))
                : _createCommentVNode("", true),
              ($props.item.utmTerm)
                ? (_openBlock(), _createElementBlock("p", _hoisted_15, [
                    _cache[11] || (_cache[11] = _createTextVNode(" Term: ")),
                    _createElementVNode("span", _hoisted_16, _toDisplayString($props.item.utmTerm), 1)
                  ]))
                : _createCommentVNode("", true),
              (
                            $props.item.customParameters &&
                            $props.item.customParameters.length > 0
                        )
                ? (_openBlock(), _createElementBlock("p", _hoisted_17, [
                    _cache[12] || (_cache[12] = _createTextVNode(" Params: ")),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.item.customParameters, (param) => {
                      return (_openBlock(), _createElementBlock("span", {
                        class: "text-gray-900",
                        key: param.key
                      }, _toDisplayString(param.key) + "=" + _toDisplayString(param.value), 1))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.editMode)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _createVNode(_component_InputUrlForm, { item: $props.item }, null, 8, ["item"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_19, [
        (!_ctx.editMode)
          ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("a", {
                  class: "text-indigo-600 list-item__short-url-a",
                  target: "_blank",
                  href: $options.shortUrl
                }, _toDisplayString($options.shortUrl), 9, _hoisted_22),
                _createElementVNode("input", {
                  type: "hidden",
                  id: 'copy-url_' + $props.item.linkId,
                  value: $options.shortUrl
                }, null, 8, _hoisted_23)
              ]),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("a", {
                    href: "#",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($options.copy($props.item, $event)), ["prevent"])),
                    class: "w-full flex items-center justify-center px-2 py-1 border border-transparent text-sm leading-6 font-light rounded-md text-indigo-600 bg-white hover:text-indigo-500 focus:outline-none focus:border-indigo-300 focus:ring-indigo bg-indigo-100 hover:bg-indigo-50 active:bg-indigo-200 md:py-2 md:px-4"
                  }, " Copy URL ")
                ]),
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("a", {
                    href: "#",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($options.edit($props.item)), ["prevent"])),
                    class: "font-medium text-sm text-gray-600 hover:text-indigo-500"
                  }, " Edit ")
                ]),
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("a", {
                    href: "#",
                    onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => ($options.duplicate($props.item)), ["prevent"])),
                    class: "font-medium text-sm text-gray-600 hover:text-indigo-500"
                  }, " Duplicate ")
                ]),
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("a", {
                    href: "#",
                    onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => ($options.deleteUrl($props.item)), ["prevent"])),
                    class: "font-medium text-sm text-gray-600 hover:text-red-500"
                  }, " Delete ")
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.editMode)
          ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
              _createElementVNode("div", null, [
                _createElementVNode("a", {
                  class: "text-indigo-600 list-item__short-url-a",
                  target: "_blank",
                  href: $options.shortUrl
                }, _toDisplayString($options.shortUrl), 9, _hoisted_30)
              ]),
              _createElementVNode("div", _hoisted_31, [
                _createElementVNode("button", {
                  onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => ($options.save($props.item)), ["prevent"])),
                  disabled: _ctx.saveDisabled,
                  class: _normalizeClass([{
                                'opacity-50 cursor-not-allowed': _ctx.saveDisabled,
                                'focus:border-indigo-300 focus:ring-indigo hover:bg-emerald-400 active:bg-emerald-600':
                                    !_ctx.saveDisabled,
                            }, "w-full flex items-center justify-center px-4 py-1 border border-transparent text-sm leading-6 font-light rounded-md text-white focus:outline-none bg-emerald-100 bg-emerald-500 md:py-2 md:px-6"])
                }, " Save ", 10, _hoisted_32)
              ]),
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("a", {
                  href: "#",
                  onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => ($options.cancelEdit && $options.cancelEdit(...args)), ["prevent"])),
                  class: "font-medium text-sm text-gray-600 hover:text-indigo-500"
                }, " Cancel ")
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_34, [
      _createElementVNode("div", null, [
        _cache[13] || (_cache[13] = _createElementVNode("span", { class: "text-gray-400 mr-1" }, "Created on:", -1)),
        _createElementVNode("span", _hoisted_35, _toDisplayString(this.$filters.formatTimestamp($props.item.created)), 1)
      ]),
      ($props.item.updated)
        ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
            _cache[14] || (_cache[14] = _createElementVNode("span", { class: "text-gray-400 mr-1" }, "Updated on:", -1)),
            _createElementVNode("span", _hoisted_37, _toDisplayString(this.$filters.formatTimestamp($props.item.updated)), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}