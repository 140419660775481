<template>
    <div class="create-new-url">
        <form
            @submit.prevent="shortenUrl"
            class="grid grid-cols-1 gap-y-6 md:grid-cols-6 sm:gap-x-8"
        >
            <div class="md:col-span-3">
                <InputUrlForm />
            </div>
            <div class="md:col-span-2">
                <div class="mb-6">
                    <div class="flex">
                        <label
                            for="short-name"
                            class="block text-sm font-medium leading-5 text-gray-700 pb-1"
                        >
                            Short name
                        </label>
                        <div
                            class="flex ml-auto"
                            style="align-items: center"
                            v-if="this.nextName"
                        >
                            <div class="mr-2">
                                <svg
                                    width="9px"
                                    height="16px"
                                    viewBox="0 0 9 16"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                >
                                    <g
                                        id="Page-1"
                                        stroke="none"
                                        stroke-width="1"
                                        fill="none"
                                        fill-rule="evenodd"
                                    >
                                        <g
                                            id="bolt-thunder-svgrepo-com"
                                            fill="#5145cd"
                                            fill-rule="nonzero"
                                        >
                                            <path
                                                d="M8.70122417,4.82576218 C8.56467836,4.55909552 8.29030799,4.391423 7.99061209,4.391423 L6.33596881,4.391423 L7.54348538,1.07125146 C7.63237427,0.826385965 7.59644444,0.553606238 7.4471423,0.340397661 C7.29755945,0.126939571 7.05350487,0 6.79295127,0 L2.40152827,0 C2.03505653,0 1.71568031,0.249387914 1.62704094,0.604693957 L0.0301598441,6.99221832 C-0.0294424951,7.23069006 0.0240467836,7.48353996 0.175469786,7.67728655 C0.326643275,7.87103314 0.558721248,7.98440546 0.804647173,7.98440546 L2.47391813,7.98440546 L0.0511812865,14.9066355 C-0.0800311891,15.2810916 0.0839298246,15.6952203 0.435773879,15.8786121 C0.553138402,15.9395556 0.679298246,15.9688421 0.804116959,15.9688421 C1.05403509,15.9688421 1.29808967,15.8514776 1.45244444,15.6369591 L8.63840936,5.65645224 C8.81378558,5.41317739 8.83801949,5.09246004 8.70122417,4.82576218 Z M0.804647173,15.1703704 L3.59918908,7.18596491 L0.804647173,7.18596491 L2.40152827,0.798440546 L6.79295127,0.798440546 L5.19607018,5.18986355 L7.99061209,5.18986355 L0.804647173,15.1703704 Z"
                                                id="Shape"
                                            ></path>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div
                                class="text-sm text-indigo-700 cursor-pointer"
                                @click="pickShortName"
                            >
                                Pick "{{ nextName }}"
                            </div>
                        </div>
                    </div>
                    <div class="mt-1 relative rounded-md">
                        <input
                            id="short-name"
                            v-model="shortName"
                            class="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                            placeholder="etc.: sale, offer25, 8754e1 .."
                        />
                    </div>
                    <div
                        v-if="this.errors.shortName"
                        class="text-red-500 text-xs italic mt-1"
                    >
                        {{ errors.shortName }}
                    </div>
                    <div
                        v-if="this.warnings.shortName"
                        class="text-yellow-500 text-xs italic mt-1"
                    >
                        {{ warnings.shortName }}
                    </div>
                </div>
                <div class="mb-6">
                    <label
                        for="tag-name"
                        class="block text-sm font-medium leading-5 text-gray-700 flex items-center"
                    >
                        <span class="mr-1">Tag (optional)</span>
                        <span @click.prevent="toggleHelp">
                            <svg
                                class="h-5 w-5 text-gray-600"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                        </span>
                    </label>
                    <div class="mt-1 relative rounded-md">
                        <input
                            id="tag-name"
                            v-model="tagName"
                            @blur="handleTagNameBlur"
                            class="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                            placeholder="video id, FB post id.."
                        />
                    </div>
                    <div
                        v-if="this.errors.tagName"
                        class="text-red-500 text-xs italic mt-1"
                    >
                        {{ errors.tagName }}
                    </div>
                    <div
                        v-if="this.errors.shortAndTagNames"
                        class="text-red-500 text-xs italic mt-1"
                    >
                        {{ errors.shortAndTagNames }}
                    </div>
                    <div
                        class="text-gray-500 text-xs italic mt-1"
                        v-if="tagHelpVisible"
                    >
                        Provide tag to use same short name.<br />
                        Etc.: <b>{{ shortenerDomain }}/Nord-Sale/123</b> and
                        <b>{{ shortenerDomain }}/Nord-Sale/xyz</b>
                    </div>
                </div>
            </div>
            <div>
                <span class="md:mt-6 w-full inline-flex rounded-md">
                    <button
                        type="submit"
                        class="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-500 focus:outline-none focus:border-green-700 focus:ring-green active:bg-emerald-700 transition ease-in-out duration-150"
                    >
                        Shorten
                    </button>
                </span>
            </div>
        </form>
    </div>
</template>

<style lang="scss">
.create-new-url {
    &__icon-arrow {
        position: absolute;
        right: -22px;
        top: 22px;
        opacity: 0.5;
        display: none;

        @media (min-width: 768px) {
            display: block;
        }
    }
}
</style>

<script lang="ts">
import store from "../store";
import {
    validateShortName,
    validateTagName,
    validateShortAndTagNames,
    validateShortNameLength,
} from "../common/validators";
import InputUrlForm from "@/components/InputUrlForm.vue";
import { getShortUrl, getGeneratedName } from "../utils";

export default {
    name: "CreateNewUrl",
    components: {
        InputUrlForm,
    },
    props: {
        items: Array,
    },
    data: function () {
        return {
            // Form fields
            shortenerDomain: store.getters.getShortenerDomain,
            shortName: "",
            tagName: "",
            tagHelpVisible: false,

            // Additional
            nextName: "",
            errors: {},
            warnings: {},
            valid: true,
        };
    },
    created() {
        this.$emitter.on("utmFormCallBack-new", (utmForm) => {
            this.errors = {};

            // Validate fields separately
            const validShortName = validateShortName(
                this.shortName,
                this.items
            );
            this.errors.shortName = validShortName.error;

            // If already taken, show suggestion for next name
            if (!validShortName.valid && validShortName.rule === "unique") {
                this.nextName = `${this.shortName}_1`;
            }

            const validTagName = validateTagName(this.tagName);
            this.errors.tagName = validTagName.error;

            if (this.shortName && this.tagName) {
                // Validate both short name and tag
                // Allow duplicate short names with unique tags
                const validShortAndTagNames = validateShortAndTagNames(
                    this.shortName,
                    this.tagName,
                    this.items
                );
                this.errors.shortAndTagNames = validShortAndTagNames.error;

                // Allow non unique short names when tag is used
                if (validShortName.rule == "unique") {
                    validShortName.valid = true;
                    this.errors.shortName = null;
                }

                this.valid =
                    validShortName.valid &&
                    validTagName.valid &&
                    validShortAndTagNames.valid &&
                    utmForm.valid;
            } else {
                // Own fields + utm form
                this.valid = validShortName.valid && utmForm.valid;
            }

            if (this.valid) {
                const customParams = utmForm.fields.customParameters.filter(
                    (item) => item.key
                );

                this.save({
                    url: utmForm.fields.urlSource,
                    utmSource: utmForm.fields.utmSource,
                    utmMedium: utmForm.fields.utmMedium,
                    utmCampaign: utmForm.fields.utmCampaign,
                    utmTerm: utmForm.fields.utmTerm,
                    utmContent: utmForm.fields.utmContent,
                    customParameters: customParams,
                    shortName: this.shortName,
                    tagName: this.tagName,
                });
            }
        });

        this.$emitter.on("apiCallBack-new", (res) => {
            if (res.err) {
                this.valid = false;
                this.errors.shortName = res.err;
                this.shortName = res.shortName;
                this.nextName = "";
            }
        });

        // Prefill form
        this.$emitter.on("cloneItem", (clonedItem) => {
            this.shortName = clonedItem.shortName;
            this.tagName = clonedItem.tagName;

            if (
                clonedItem.utmSource ||
                clonedItem.utmMedium ||
                clonedItem.utmName ||
                clonedItem.tagName
            ) {
                this.$emitter.emit("setUrlTypeToUtm");
            }

            this.$emitter.emit("fillInputForm", clonedItem);
        });
    },
    methods: {
        shortenUrl() {
            // Trigger utm form validation
            this.$emitter.emit("validateUtmForm-new");
        },
        save(item) {
            // Separate emit, in order to preserve utm fields
            this.$emitter.emit("shortenUrl", item);

            // Clear form fields, restore default value
            this.shortName = "";
            this.tagName = "";
            this.$emitter.emit("clearUtmForm-new");

            // Close edit mode of other items
            this.$emitter.emit("cancelEditModeForAll");
        },
        toggleHelp() {
            this.tagHelpVisible = !this.tagHelpVisible;
        },
        handleTagNameBlur() {
            if (this.tagName) {
                // copy tag name to UTM term
                this.$emitter.emit("setUrlTypeToUtm");
                this.$emitter.emit("setUtmTerm", this.tagName);
            }
        },
        checkShortUrl() {
            const shortUrl = getShortUrl(this.shortName, this.tagName);
            const validShortNameLength = validateShortNameLength(shortUrl);
            this.warnings.shortName = validShortNameLength.warning;

            // Suggest auto generated name
            this.nextName = getGeneratedName(this.shortName, this.items);
        },
        pickShortName() {
            this.shortName = this.nextName;
            this.errors.shortName = "";
        },
    },
    watch: {
        shortName() {
            this.checkShortUrl();
        },
        tagName() {
            this.checkShortUrl();
        },
    },
    unmounted() {
        // Unregister listener
        this.$emitter.off("utmFormCallBack-new");
        this.$emitter.off("apiCallBack-new");
        this.$emitter.off("cloneItem");
    },
};
</script>
