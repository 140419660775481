<template>
    <div class="border-b">
        <div
            class="list-item px-4 pt-6 pb-6 md:px-8 md:flex items-center relative"
        >
            <div class="list-item__col list-item__col-a mb-2 md:mb-0">
                <div v-if="!editMode" class="break-all">
                    <p v-if="item.url" class="text-sm text-gray-400">
                        Url: <span class="text-gray-900">{{ item.url }}</span>
                    </p>
                    <p v-if="item.utmCampaign" class="text-sm text-gray-400">
                        Campaign:
                        <span class="text-gray-900">{{
                            item.utmCampaign
                        }}</span>
                    </p>
                    <p v-if="item.utmContent" class="text-sm text-gray-400">
                        Content:
                        <span class="text-gray-900">{{ item.utmContent }}</span>
                    </p>
                    <p v-if="item.utmMedium" class="text-sm text-gray-400">
                        Medium:
                        <span class="text-gray-900">{{ item.utmMedium }}</span>
                    </p>
                    <p v-if="item.utmSource" class="text-sm text-gray-400">
                        Source:
                        <span class="text-gray-900">{{ item.utmSource }}</span>
                    </p>
                    <p v-if="item.utmTerm" class="text-sm text-gray-400">
                        Term:
                        <span class="text-gray-900">{{ item.utmTerm }}</span>
                    </p>
                    <p
                        v-if="
                            item.customParameters &&
                            item.customParameters.length > 0
                        "
                        class="text-sm text-gray-400"
                    >
                        Params:
                        <span
                            class="text-gray-900"
                            v-for="param in item.customParameters"
                            :key="param.key"
                        >
                            {{ param.key }}={{ param.value }}
                        </span>
                    </p>
                </div>
                <div v-if="editMode">
                    <InputUrlForm :item="item" />
                </div>
            </div>
            <div class="list-item__col list-item__col-b">
                <div v-if="!editMode" class="md:flex items-center justify-end">
                    <div class="list-item__short-url">
                        <a
                            class="text-indigo-600 list-item__short-url-a"
                            target="_blank"
                            :href="shortUrl"
                            >{{ shortUrl }}</a
                        >
                        <input
                            type="hidden"
                            :id="'copy-url_' + item.linkId"
                            :value="shortUrl"
                        />
                    </div>

                    <div class="flex">
                        <div class="mt-3 rounded-md md:mt-0 md:ml-6">
                            <a
                                href="#"
                                @click.prevent="copy(item, $event)"
                                class="w-full flex items-center justify-center px-2 py-1 border border-transparent text-sm leading-6 font-light rounded-md text-indigo-600 bg-white hover:text-indigo-500 focus:outline-none focus:border-indigo-300 focus:ring-indigo bg-indigo-100 hover:bg-indigo-50 active:bg-indigo-200 md:py-2 md:px-4"
                            >
                                Copy URL
                            </a>
                        </div>
                        <div
                            class="mt-3 md:mt-0 ml-3 md:ml-6 flex items-center"
                        >
                            <a
                                href="#"
                                @click.prevent="edit(item)"
                                class="font-medium text-sm text-gray-600 hover:text-indigo-500"
                            >
                                Edit
                            </a>
                        </div>
                        <div
                            class="mt-3 md:mt-0 ml-3 md:ml-6 flex items-center"
                        >
                            <a
                                href="#"
                                @click.prevent="duplicate(item)"
                                class="font-medium text-sm text-gray-600 hover:text-indigo-500"
                            >
                                Duplicate
                            </a>
                        </div>
                        <div
                            class="mt-3 md:mt-0 ml-3 md:ml-6 flex items-center"
                        >
                            <a
                                href="#"
                                @click.prevent="deleteUrl(item)"
                                class="font-medium text-sm text-gray-600 hover:text-red-500"
                            >
                                Delete
                            </a>
                        </div>
                    </div>
                </div>
                <div v-if="editMode" class="md:flex items-center justify-end">
                    <div>
                        <a
                            class="text-indigo-600 list-item__short-url-a"
                            target="_blank"
                            :href="shortUrl"
                            >{{ shortUrl }}</a
                        >
                    </div>
                    <div class="mt-3 rounded-md md:mt-0 md:ml-6">
                        <button
                            @click.prevent="save(item)"
                            :disabled="saveDisabled"
                            :class="{
                                'opacity-50 cursor-not-allowed': saveDisabled,
                                'focus:border-indigo-300 focus:ring-indigo hover:bg-emerald-400 active:bg-emerald-600':
                                    !saveDisabled,
                            }"
                            class="w-full flex items-center justify-center px-4 py-1 border border-transparent text-sm leading-6 font-light rounded-md text-white focus:outline-none bg-emerald-100 bg-emerald-500 md:py-2 md:px-6"
                        >
                            Save
                        </button>
                    </div>
                    <div class="mt-3 md:mt-0 ml-3 md:ml-6 flex items-center">
                        <a
                            href="#"
                            @click.prevent="cancelEdit"
                            class="font-medium text-sm text-gray-600 hover:text-indigo-500"
                        >
                            Cancel
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-xs px-4 pb-4 md:px-8">
            <div>
                <span class="text-gray-400 mr-1">Created on:</span>
                <span class="text-gray-600" style="display: inline-block">{{
                    this.$filters.formatTimestamp(item.created)
                }}</span>
            </div>
            <div v-if="item.updated">
                <span class="text-gray-400 mr-1">Updated on:</span>
                <span class="text-gray-600" style="display: inline-block">{{
                    this.$filters.formatTimestamp(item.updated)
                }}</span>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.list-item {
    @media (min-width: 768px) {
        &__col-a {
            width: 40%;
        }

        &__col-b {
            width: 60%;
        }

        &__short-url {
            max-width: calc(100% - 250px);
        }
    }

    &__short-url-a {
        overflow-wrap: break-word;
    }
}
</style>

<script lang="ts">
import InputUrlForm from "@/components/InputUrlForm.vue";
import { getShortUrl } from "../utils";

export default {
    name: "UrlListItem",
    components: {
        InputUrlForm,
    },
    props: {
        item: Object,
        items: Array,
    },
    data: function () {
        return {
            editMode: false,
            urlInput: "",
            errors: {},
            valid: true,
            saveDisabled: false,
        };
    },
    computed: {
        shortUrl() {
            return getShortUrl(this.item.shortName, this.item.tagName);
        },
    },
    methods: {
        copy(item) {
            // copy input value to clipboard
            let shortUrlInput = document.querySelector(
                `#copy-url_${item.linkId}`
            );

            if (shortUrlInput) {
                navigator.clipboard
                    .writeText(shortUrlInput.getAttribute("value"))
                    .then(() => {
                        this.$toast.success("URL copied to clipboard");
                    })
                    .catch(() => {
                        this.$toast.error("Failed to copy URL to clipboard");
                    });
            }
        },
        edit() {
            this.editMode = true;
        },
        duplicate(item) {
            this.$emitter.emit("openDuplicatePopup", item);
        },
        cancelEdit() {
            this.editMode = false;
            this.errors = {};
        },
        deleteUrl() {
            this.editMode = false;
            this.$emitter.emit("deleteUrl", this.item);
        },
        save() {
            this.$emitter.emit(`validateUtmForm-${this.item.linkId}`);
        },
    },
    created() {
        // Register events
        this.$emitter.on("cancelEditModeForAll", () => (this.editMode = false));

        this.$emitter.on(`utmFormCallBack-${this.item.linkId}`, (utmForm) => {
            if (utmForm.valid) {
                this.editMode = false;

                const customParams = utmForm.fields.customParameters.filter(
                    (item) => item.key
                );

                this.$emitter.emit("postLink", {
                    // original fields from item
                    linkId: this.item.linkId,
                    shortName: this.item.shortName,
                    created: this.item.created,
                    updated: this.item.updated,

                    // updated fields from utmForm
                    url: utmForm.fields.urlSource,
                    utmSource: utmForm.fields.utmSource,
                    utmMedium: utmForm.fields.utmMedium,
                    utmCampaign: utmForm.fields.utmCampaign,
                    utmTerm: utmForm.fields.utmTerm,
                    utmContent: utmForm.fields.utmContent,
                    customParameters: customParams,
                });
            }
        });
    },
    unmounted() {
        // Unregister listeners
        this.$emitter.off("cancelEditModeForAll");
        this.$emitter.off(`utmFormCallBack-${this.item.linkId}`);
    },
};
</script>
